import { translations } from "@aws-amplify/ui";
import { Amplify, I18n } from "aws-amplify";

export const setupAws = () => {
  I18n.putVocabularies(translations);
  I18n.setLanguage("ja");
  Amplify.configure({ ...cognito });
};

export const cognito = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ["openid"],
      redirectSignIn: process.env.REACT_APP_URL,
      redirectSignOut: process.env.REACT_APP_URL,
      responseType: "code",
    },
  },
};
