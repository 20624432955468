import { format, formatDistance } from "date-fns";
import { ja } from "date-fns/locale";

export const incrementMonth = (date: string | Date, incrementMonth: number) => {
  const dt = new Date(date);
  dt.setMonth(dt.getMonth() + incrementMonth);
  return formatDate(dt);
};

export const formatDateJpn = (date: string | Date) => {
  return format(new Date(date), "yyyy年 MM月 dd日");
};

export const formatDate = (date: string | Date) => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const formatDateTime = (date: string | Date) => {
  return format(new Date(date), "yyyy-MM-dd HH:MM:ss");
};

export const elapsedDateTime = (date: string | Date) => {
  const time = formatDistance(new Date(), new Date(date), {
    locale: ja,
  });
  if (time.indexOf("未満") !== -1) {
    return "たった今";
  } else if (time.indexOf("か月") !== -1 || time.indexOf("年") !== -1) {
    return format(new Date(date), "yyyy年MM月dd日", {
      locale: ja,
    });
  } else {
    return time + "前";
  }
};
