import { KeyboardEvent, useRef } from "react";

// 要素のrefの順番に応じてEnterカーソル移動
export const useEnterKeyFocusControl = (atLastIndexFunc: () => void) => {
  const ref = useRef(new Map<number, HTMLInputElement>());
  return (index: number) => ({
    onKeyDown(event: KeyboardEvent<HTMLElement>) {
      if (event.code !== "Enter" && event.code !== "NumpadEnter") return;
      if (event.nativeEvent.isComposing) return; // IME変換中のenterを無視する。
      const sortedIndices = [...ref.current.keys()].sort();
      const nextIndex = sortedIndices[sortedIndices.indexOf(index) + 1];
      if (typeof nextIndex === "number") {
        ref.current.get(nextIndex)?.focus();
      } else if (atLastIndexFunc) {
        atLastIndexFunc();
      }
    },
    inputRef(element: HTMLInputElement | null) {
      if (element) ref.current.set(index, element);
      else ref.current.delete(index);
    },
  });
};
