import "@aws-amplify/ui-react/styles.css";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Box, IconButton, Stack, TableRow, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import "./App.css";
import { LoadingButton } from "./components/Button";
import { DatePicker } from "./components/DatePicker";
import { PriceTableCell, TableCell, TableComponents } from "./components/Table";
import { TextField } from "./components/TextField";
import { AuthProvider } from "./contexts/AuthContext";
import { useEnterKeyFocusControl } from "./hooks/useEnterKeyFocusControl";
import { usePasts } from "./hooks/usePast";
import {
  tableCodeCellStyle,
  tableDateCellStyle,
  tableItemNumCellStyle,
  tableNameCellStyle,
  tablePriceCellStyle,
  typographyMainStyle,
  typographySubStyle,
} from "./style";

const partnerQueryTypes: { [key: string]: string } = {
  partnerName: "取引先名",
  partnerKana: "取引先名かな",
  partnerCode: "取引先コード",
};

const itemQueryTypes: { [key: string]: string } = {
  itemName: "商品名・規格",
  itemNameKana: "商品名かな・規格",
};

export const App = () => {
  const [partnerQueryType, setPartnerQueryType] = useState("partnerName");
  const [itemQueryType, setItemQueryType] = useState("itemName");
  const { pasts, loading, searchPasts, moreSearchPasts, query, setQuery } =
    usePasts();
  const register = useEnterKeyFocusControl(async () => {
    await searchPasts();
    if (firstInputRef.current) {
      firstInputRef.current.click();
      firstInputRef.current.focus();
    }
  });
  const firstInputRef = useRef<HTMLInputElement | null>(null);

  const handleChangePartnerQueryType = () => {
    if (partnerQueryType === "partnerName") {
      setPartnerQueryType("partnerKana");
      const { partnerName, ...otherQuery } = query;
      setQuery({ ...otherQuery });
    } else if (partnerQueryType === "partnerKana") {
      setPartnerQueryType("partnerCode");
      const { partnerKana, ...otherQuery } = query;
      setQuery({ ...otherQuery });
    } else if (partnerQueryType === "partnerCode") {
      setPartnerQueryType("partnerName");
      const { partnerCode, ...otherQuery } = query;
      setQuery({ ...otherQuery });
    }
  };

  const handleChangeItemQueryType = () => {
    if (itemQueryType === "itemName") {
      setItemQueryType("itemNameKana");
      const { itemName, ...otherQuery } = query;
      setQuery({ ...otherQuery });
    } else if (itemQueryType === "itemNameKana") {
      setItemQueryType("itemName");
      const { itemNameKana, ...otherQuery } = query;
      setQuery({ ...otherQuery });
    }
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleStartDateCallbackFunc = (date: string) => {
    setQuery({ ...query, startDate: date });
  };

  const handleEndDateCallbackFunc = (date: string) => {
    setQuery({ ...query, endDate: date });
  };

  return (
    <AuthProvider>
      <Box sx={{ minHeight: 62, whiteSpace: "nowrap", overflowX: "scroll" }}>
        <TextField
          key={partnerQueryType}
          name={partnerQueryType}
          label={partnerQueryTypes[partnerQueryType]}
          onChange={handleOnChange}
          value={query[partnerQueryType]}
          {...register(0)}
          inputRef={firstInputRef}
          autoFocus
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ width: 16, height: 16 }}
                onClick={handleChangePartnerQueryType}
              >
                <RepeatIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          key={itemQueryType}
          name={itemQueryType}
          label={itemQueryTypes[itemQueryType]}
          onChange={handleOnChange}
          value={query[itemQueryType]}
          {...register(1)}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ width: 16, height: 16 }}
                onClick={handleChangeItemQueryType}
              >
                <RepeatIcon />
              </IconButton>
            ),
          }}
        />
        <TextField
          name="makerName"
          label="メーカー名"
          onChange={handleOnChange}
          value={query.makerName}
          {...register(2)}
        />
        <TextField
          name="tekiyo"
          label="摘要"
          onChange={handleOnChange}
          value={query.tekiyo}
          {...register(3)}
        />
        <DatePicker
          label="売上日"
          value={query.startDate}
          datePickerCallbackFunc={handleStartDateCallbackFunc}
          isEditMode
          {...register(4)}
        />
        <DatePicker
          label=" "
          value={query.endDate}
          datePickerCallbackFunc={handleEndDateCallbackFunc}
          isEditMode
          {...register(5)}
        />
        <LoadingButton loading={loading} onClick={() => searchPasts()}>
          検索
        </LoadingButton>
      </Box>
      <TableVirtuoso
        style={{ height: document.documentElement.clientHeight - 62 }}
        data={pasts}
        components={TableComponents}
        endReached={moreSearchPasts}
        overscan={200}
        fixedHeaderContent={() => (
          <TableRow sx={{ background: "white" }}>
            <TableCell sx={tableDateCellStyle}>売上日</TableCell>
            <TableCell sx={tableNameCellStyle}>取引先名</TableCell>
            <TableCell sx={tableCodeCellStyle}>HSS商品CD</TableCell>
            <TableCell sx={tableNameCellStyle}>商品名</TableCell>
            <TableCell sx={tableItemNumCellStyle}>数量</TableCell>
            <TableCell sx={tablePriceCellStyle}>定価</TableCell>
            <TableCell sx={tablePriceCellStyle}>売価</TableCell>
            <TableCell sx={tablePriceCellStyle}>原価</TableCell>
          </TableRow>
        )}
        itemContent={(index, item) => {
          return item ? (
            <>
              <TableCell sx={tableDateCellStyle}>
                {item.orderDate.slice(0, 10)}
              </TableCell>
              <TableCell sx={tableNameCellStyle}>
                <Stack>
                  <Typography sx={{ ...typographySubStyle }}>
                    {item.partnerCode}
                  </Typography>
                  <Typography sx={{ ...typographyMainStyle }}>
                    {item.partnerName}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell sx={tableCodeCellStyle}>
                <Typography
                  sx={{
                    ...typographyMainStyle,
                    color: item.itemNumber >= 0 ? "#000000" : "#FF0000",
                  }}
                >
                  {item.itemCode}
                </Typography>
              </TableCell>
              <TableCell sx={tableNameCellStyle}>
                <Stack>
                  <Typography
                    sx={{
                      ...typographySubStyle,
                      color: item.itemNumber >= 0 ? "gray" : "#FF0000",
                    }}
                  >
                    {item.makerName}
                  </Typography>
                  <Typography
                    sx={{
                      ...typographyMainStyle,
                      color: item.itemNumber >= 0 ? "#000000" : "#FF0000",
                    }}
                  >
                    {item.itemName}
                  </Typography>
                  {item.tekiyo !== "" && (
                    <Typography
                      sx={{
                        ...typographyMainStyle,
                        color: item.itemNumber >= 0 ? "#000000" : "#FF0000",
                      }}
                    >
                      摘要：{item.tekiyo}
                    </Typography>
                  )}
                </Stack>
              </TableCell>
              <TableCell sx={tableItemNumCellStyle}>
                <Typography
                  sx={{
                    ...typographyMainStyle,
                    color: item.itemNumber >= 0 ? "#000000" : "#FF0000",
                  }}
                >
                  {item.itemNumber}
                </Typography>
              </TableCell>
              <PriceTableCell
                itemNumber={item.itemNumber}
                unitPrice={`${item.listUnitPrice}`}
              />
              <PriceTableCell
                itemNumber={item.itemNumber}
                unitPrice={`${item.salesUnitPrice}`}
              />
              <PriceTableCell
                itemNumber={item.itemNumber}
                unitPrice={`${item.purchaseUnitPrice}`}
              />
            </>
          ) : (
            <></>
          );
        }}
      />
    </AuthProvider>
  );
};
