import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const typographyMainStyle: React.CSSProperties = {
  margin: 0,
  overflowWrap: "break-word",
  wordBreak: "break-all",
  fontSize: "small",
};
export const typographySubStyle: React.CSSProperties = {
  fontSize: "x-small",
  color: "gray",
};
export const typographyItemNumStyle: React.CSSProperties = {
  margin: 0,
  overflowWrap: "break-word",
  textAlign: "center",
};

export const tableNameCellStyle: SxProps<Theme> = {
  width: "450px",
  minWidth: "450px",
};

export const tableCodeCellStyle: SxProps<Theme> = {
  width: "150px",
  minWidth: "150px",
};

export const tableDateCellStyle: SxProps<Theme> = {
  width: "90px",
  minWidth: "90px",
};

export const tableTightCellStyle: SxProps<Theme> = {
  width: "90px",
  minWidth: "90px",
};

export const tableActionCellStyle: SxProps<Theme> = {
  pr: 0,
  width: "50px",
};

export const tableItemNumCellStyle: SxProps<Theme> = {
  minWidth: "65px",
  width: "65px",
  textAlign: "right",
};

export const tablePriceCellStyle: SxProps<Theme> = {
  minWidth: "90px",
  width: "90px",
  textAlign: "right",
};

export const tableGrossProfitRatioCellStyle: SxProps<Theme> = {
  minWidth: "50px",
  width: "50px",
  textAlign: "right",
};

export const tableBodyRowStyle: SxProps<Theme> = {
  "&:last-child td, &:last-child th": { border: 0 },
  "&:nth-of-type(2n+1)": { background: "#fafafa" },
};
