import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { ContainedButton } from "./Button";

export const ServiceWorkerUpdateDialog = (props: {
  registration: ServiceWorkerRegistration;
}) => {
  const { registration } = props;
  const [show, setShow] = useState(!!registration.waiting);

  const handleUpdate = () => {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    setShow(false);
  };

  return (
    <Dialog open={show}>
      <DialogTitle style={{ textAlign: "center" }}>
        アップデート通知
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          新しいバージョンがリリースされました。
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <ContainedButton onClick={handleUpdate}>アップデート</ContainedButton>
      </DialogActions>
    </Dialog>
  );
};
