import { TextField as MUITextField, TextFieldProps } from "@mui/material";
import {
  ChangeEvent,
  CompositionEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    const { onChange, value, ...otherProps } = props;
    const onComposition = useRef(false);
    const [inputValue, setInputValue] = useState<unknown>("");

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleOnComposition = (
      event:
        | CompositionEvent<HTMLDivElement>
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setInputValue(
        (event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target
          .value
      );

      if (event && event.type === "compositionstart") {
        onComposition.current = true;
        return;
      }

      if (
        (event && event.type === "compositionend") ||
        (event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>).target
          .value === ""
      ) {
        onComposition.current = false;
      }

      if (!onComposition.current && onChange) {
        onChange(event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
      }
    };

    return (
      <MUITextField
        autoComplete="off"
        variant="standard"
        size="small"
        ref={ref}
        InputLabelProps={{ shrink: true }}
        {...otherProps}
        value={inputValue || value}
        sx={{ m: 1, ...props.sx }}
        onChange={handleOnComposition}
        onCompositionEnd={handleOnComposition}
        onCompositionStart={handleOnComposition}
      />
    );
  }
);
