import { Auth } from "aws-amplify";
import axios from "axios";
import { useCallback, useState } from "react";

type Past = {
  code: number;
  rowNumber: number;
  orderDate: string;
  partnerCode: number;
  partnerName: string;
  partnerNameKana: string;
  itemCode: string;
  itemName: string;
  itemNameKana: string;
  makerName: string;
  itemNumber: number;
  listUnitPrice: number;
  salesUnitPrice: number;
  purchaseUnitPrice: number;
  tekiyo: string;
};

type Query = {
  [key: string]: string;
};

const defaultQuery: Query = {
  startDate: "",
  endDate: "",
};

export const usePasts = () => {
  const [pasts, setPasts] = useState<(Past | null)[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [noMoreRow, setNoMoreRow] = useState(true);
  const [query, setQuery] = useState<Query>(defaultQuery);

  const getCognitoIdToken = async (): Promise<string> => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    return cognitoUser.signInUserSession.idToken.jwtToken;
  };

  const searchPasts = useCallback(async () => {
    setLoading(true);
    setPasts([]);
    const headers = { Authorization: await getCognitoIdToken() };
    if (!headers.Authorization) Auth.signOut();
    await axios
      .post(
        process.env.REACT_APP_API_GATEWAY_REST_API_ENDPOINT || "",
        { ...query, skip: 0 },
        { headers }
      )
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPasts(response.data);
          if (response.data.length < 100) {
            setNoMoreRow(true);
          } else {
            setNoMoreRow(false);
          }
        } else {
          setNoMoreRow(true);
          throw Error(response.data);
        }
      })
      .catch((response) => {
        alert("エラー");
        console.log(response);
      });
    setLoading(false);
  }, [query]);

  const moreSearchPasts = useCallback(async () => {
    if (!noMoreRow) {
      setLoading(true);
      const headers = { Authorization: await getCognitoIdToken() };
      await axios
        .post(
          process.env.REACT_APP_API_GATEWAY_REST_API_ENDPOINT || "",
          { ...query, skip: pasts.length },
          { headers }
        )
        .then((response) => {
          if (Array.isArray(response.data)) {
            setPasts([...pasts, ...response.data]);
            if (response.data.length < 100) {
              setNoMoreRow(true);
            } else {
              setNoMoreRow(false);
            }
          } else {
            setNoMoreRow(true);
            throw Error(response.data);
          }
        })
        .catch((response) => {
          alert("エラー");
          console.log(response);
        });
      setLoading(false);
    }
  }, [noMoreRow, query, pasts]);

  return {
    pasts,
    loading,
    searchPasts,
    moreSearchPasts,
    query,
    setQuery,
  };
};
