import {
  Paper,
  Table,
  TableBody,
  TableBodyProps,
  TableCell as MUITableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import {
  tableBodyRowStyle,
  tablePriceCellStyle,
  typographyMainStyle,
  typographySubStyle,
} from "../style";
import { commaSeparated } from "../utils/format";

export interface PriceProps {
  itemNumber: number;
  unitPrice: string;
}

export const PriceTableCell = (props: PriceProps) => {
  const { itemNumber } = props;
  const unitPrice = Number(props.unitPrice);
  const codeColor = itemNumber > 0 ? "#808080" : "#FF8080";
  const color = itemNumber > 0 ? "#000000" : "#FF0000";
  return (
    <TableCell sx={tablePriceCellStyle}>
      <Typography
        style={{
          ...typographyMainStyle,
          ...typographySubStyle,
          color: codeColor,
        }}
      >
        @{commaSeparated(unitPrice)}
      </Typography>
      <Typography style={{ ...typographyMainStyle, color: color }}>
        {commaSeparated(unitPrice * itemNumber)}
      </Typography>
    </TableCell>
  );
};

export const TableCell = (props: TableCellProps) => (
  <MUITableCell
    {...props}
    sx={{
      pt: "8px",
      pb: "8px",
      pr: "8px",
      pl: "8px",
      whiteSpace: "nowrap",
      ...props.sx,
    }}
  >
    {props.children}
  </MUITableCell>
);

export const TableComponents = {
  Scroller: forwardRef<HTMLDivElement, TableContainerProps>((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props: TableProps) => (
    <Table {...props} style={{ borderCollapse: "separate" }} />
  ),
  TableHead: TableHead,
  TableRow: (props: TableRowProps) => (
    <TableRow {...props} sx={tableBodyRowStyle} />
  ),
  TableBody: forwardRef<HTMLTableSectionElement, TableBodyProps>(
    (props, ref) => <TableBody {...props} ref={ref} />
  ),
};
