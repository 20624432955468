import { TextField, TextFieldProps } from "@mui/material";
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useRef,
  useState,
} from "react";
import { useMergeRefs } from "../hooks/useMergeRefs";
import { formatDate } from "../utils/date";

export interface DatePickerProps {
  value: string | Date | null | undefined;
  datePickerCallbackFunc: (date: string) => void;
  isEditMode: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: (element: HTMLInputElement | null) => void;
}

export const DatePicker = (props: TextFieldProps & DatePickerProps) => {
  const {
    value,
    datePickerCallbackFunc,
    isEditMode,
    onKeyDown,
    inputRef,
    ...otherProps
  } = props;
  const datePickerRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(
    !value ? "" : formatDate(value)
  );

  const handleOnFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    e.target.select();
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.target.value.replace(/[^-0-9]/gi, ""));
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code !== "Enter" && event.code !== "NumpadEnter") return;
    if (event.nativeEvent.isComposing) return; // IME変換中のenterを無視する。
    if (datePickerRef.current) datePickerRef.current.blur();
    if (onKeyDown) onKeyDown(event);
  };

  const handleOnBlur = () => {
    const result = autoCompleteDate(inputValue, false);
    setInputValue(result.date);
    if (result.passed) {
      datePickerCallbackFunc(result.date);
    } else {
      if (datePickerRef.current) datePickerRef.current.focus();
    }
  };

  return (
    <TextField
      autoComplete="off"
      variant="standard"
      size="small"
      InputLabelProps={{ shrink: true }}
      {...otherProps}
      value={inputValue}
      sx={{ m: 1, minWidth: "11ch", width: "11ch" }}
      InputProps={{ readOnly: !isEditMode }}
      inputProps={{ style: { textAlign: "center" } }}
      onFocus={handleOnFocus}
      onKeyDown={handleOnKeyDown}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      inputRef={useMergeRefs(inputRef, datePickerRef)}
    />
  );
};

const autoCompleteDate = (target: string, required?: boolean) => {
  const validTarget = target.replace(/[^0-9]/gi, "");
  if (!required && validTarget === "") {
    return { date: "", passed: true };
  }

  const dt = new Date();
  let y = `${dt.getFullYear()}`;
  let m = ("00" + (dt.getMonth() + 1)).slice(-2);
  let d = ("00" + dt.getDate()).slice(-2);

  if (validTarget.length <= 2) {
    d = ("00" + validTarget).slice(-2);
  } else if (validTarget.length === 3) {
    m = ("00" + validTarget.slice(0, 1)).slice(-2);
    d = validTarget.slice(1, 3);
  } else if (validTarget.length === 4) {
    m = validTarget.slice(0, 2);
    d = validTarget.slice(2, 4);
  } else if (validTarget.length === 5) {
    y = y.slice(0, 3) + validTarget.slice(0, 1);
    m = validTarget.slice(1, 3);
    d = validTarget.slice(3, 5);
  } else if (validTarget.length === 6) {
    y = y.slice(0, 2) + validTarget.slice(0, 2);
    m = validTarget.slice(2, 4);
    d = validTarget.slice(4, 6);
  } else if (validTarget.length === 7) {
    y = y.slice(0, 1) + validTarget.slice(0, 3);
    m = validTarget.slice(3, 5);
    d = validTarget.slice(5, 7);
  } else if (validTarget.length === 8) {
    y = validTarget.slice(0, 4);
    m = validTarget.slice(4, 6);
    d = validTarget.slice(6, 8);
  } else {
    return { date: target, passed: false };
  }

  const result = `${y}-${m}-${d}`;

  if (isNaN(new Date(result).getDate())) {
    return { date: result, passed: false };
  } else {
    return { date: formatDate(result), passed: true };
  }
};
