import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { forwardRef } from "react";

export const LoadingButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & { loading?: boolean }
>((props, ref) => {
  const { loading = false, ...buttonProps } = props;

  if (loading) {
    return <CircularProgress sx={{ m: 1 }} />;
  } else {
    return (
      <Button
        variant="contained"
        tabIndex={-1}
        sx={{ m: 1 }}
        {...buttonProps}
        ref={ref}
      >
        {buttonProps.children}
      </Button>
    );
  }
});

export const ContainedButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <Button
        variant="contained"
        tabIndex={-1}
        sx={{ m: 1 }}
        {...props}
        ref={ref}
      >
        {props.children}
      </Button>
    );
  }
);
