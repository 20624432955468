import { CognitoUser } from "@aws-amplify/auth";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import { Auth, Hub } from "aws-amplify";
import { createContext, ReactNode, Suspense, useEffect, useState } from "react";
import { cognito } from "../config";

interface AuthContextProps {
  isLoggedIn: boolean;
  cognitoUser: CognitoUser | null;
}

const defaultAuthContextProps = {
  isLoggedIn: false,
  cognitoUser: null,
};

export const AuthContext = createContext<AuthContextProps>(
  defaultAuthContextProps
);

export const AuthProvider = (props: { children: ReactNode }) => {
  const [authState, setAuthState] = useState<AuthContextProps>(
    defaultAuthContextProps
  );

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setAuthState({
            ...defaultAuthContextProps,
            cognitoUser: data,
            isLoggedIn: true,
          });
          break;
        case "signOut":
          setAuthState({ ...defaultAuthContextProps });
          break;
        default:
      }
    });
    const handleAuthError = () => {
      window.location.href = `https://${cognito.Auth.oauth.domain}/login?response_type=${cognito.Auth.oauth.responseType}&client_id=${cognito.Auth.userPoolWebClientId}&redirect_uri=${cognito.Auth.oauth.redirectSignIn}`;
      setAuthState({ ...defaultAuthContextProps });
    };

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) =>
        setAuthState({
          ...defaultAuthContextProps,
          cognitoUser: cognitoUser,
          isLoggedIn: true,
        })
      )
      .catch(handleAuthError);

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={authState}>
      {authState.isLoggedIn ? (
        <Suspense
          fallback={
            <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open>
              <Stack>
                <CircularProgress color="inherit" />
                <Typography>読込中</Typography>
              </Stack>
            </Backdrop>
          }
        >
          {props.children}
        </Suspense>
      ) : (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open>
          <Stack>
            <CircularProgress color="inherit" />
            <Typography>認証中</Typography>
          </Stack>
        </Backdrop>
      )}
    </AuthContext.Provider>
  );
};
